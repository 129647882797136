import React from 'react';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import HeroSingle from '../components/pages/HeroSingle';
import WeRockWheelsSlider from '../components/pages/WeRockWheelsSlider';
import SEO from '../components/seo';
// images
import LineFull from '../images/linefull.jpg';
import priceline from '../images/pricline.png';
import '../styles/app.scss';

const WeRockOnWheels = ({ data }) => {
    const { wordpressPage: post } = data;
    const test_title = post.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");

    return (
        <>
            <SEO
                title={new_seo_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[0].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />

            <section className="page-section smallestwdt respitesec">
                <div className="container">
                    <div className="openplft">
                        <img
                            src={
                                post.acf.wron_main_image.localFile
                                    .childImageSharp.fluid.src
                            }
                            alt="We Wheels"
                        />
                        <div className="bdaybtns">
                            <a
                                href={`tel:${post.acf.wron_call_us_number}`}
                                className="wrtsbtn yellowbtn fullbtn"
                            >
                                CALL US TO BOOK: {post.acf.wron_call_us_number}
                            </a>
							
							<a
                                href="https://www.werockonwheels.com/" target="_blank"
                                className="wrtsbtn yellowbtn fullbtn"
                            >
                                VISIT WROW WEBSITE
                            </a>
                        </div>
                    </div>

                    <div className="openprgt">
                        <h2
                            className="bluetxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.wron_title,
                            }}
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: post.acf.wron_description,
                            }}
                        />
                    </div>
                    <img className="wheelsline" src={LineFull} alt="linefull" />
                </div>
            </section>

            <section className="page-section notoppaddsec smallestwdt centersec">
                <div className="container">
                    <div className="whylistrgt">
                        <h2
                            className="bluetxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.wron_book_title,
                            }}
                        />
                        <h4
                            dangerouslySetInnerHTML={{
                                __html: post.acf.wron_book_description,
                            }}
                        />
                    </div>
                </div>
            </section>

            <section className="page-section notoppaddsec smallestwdt centersec">
                <div className="container">
                    <div className="packagesdiv packagesthree">
                        {post.acf.wron_pricing_list.map((pricingBox, i) => {
                            let classBox = 'pricingbox bg-secondary text-white';
                            let classBoxText = 'yellowtxt';

                            if (i === 1) {
                                classBox = 'pricingbox bg-primary text-white';
                            } else if (i === 2) {
                                classBox = 'pricingbox bg-tertiary text-blue';
                                classBoxText = '';
                            }

                            return (
                                <div className={classBox} key={i}>
                                    <h2 className={classBoxText}>
                                        {pricingBox.wron_price_title}
                                        <br />
                                        {pricingBox.wron_price_ammount}
                                    </h2>
                                    <img src={priceline} alt="priceline" />
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                pricingBox.wron_price_description,
                                        }}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
                <a
                    href={`tel:${post.acf.wron_call_us_number}`}
                    className="wrtsbtn yellowbtn inlinebtn"
                >
                    CALL US TO BOOK: {post.acf.wron_call_us_number}
                </a>
            </section>

            <section className="page-section bg-primary text-white">
                <div className="container flexwrap bdaytxtadjust">
                    <div className="whylistrgt flexbox todwn">
                        <img
                            className="whiteborderimg"
                            src={
                                post.acf.wron_play_dates_image.localFile
                                    .childImageSharp.fluid.src
                            }
                            alt="wheels3"
                        />
                    </div>

                    <div className="whywelist flexbox toup">
                        <h2
                            className="yellowtxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.wron_play_dates_title,
                            }}
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: post.acf.wron_play_dates_content,
                            }}
                        />
                    </div>
                </div>
            </section>

            <section className="whywelist page-section">
                <div className="container flexwrap bdaytxtadjust">
                    <div className="whylistlft flexbox">
                        <h2
                            className="bluetxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.wron_private_events_title,
                            }}
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: post.acf.wron_private_events_content,
                            }}
                        />
                    </div>

                    <div className="whylistrgt flexbox">
                        <div className="equipstarimgrgt">
                            <img
                                className="whiteborderimg"
                                src={
                                    post.acf.wron_private_events_image.localFile
                                        .childImageSharp.fluid.src
                                }
                                alt="wheels5"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="page-section bg-primary text-white">
                <div className="container flexwrap bdaytxtadjust">
                    <div className="whywelist whylistlft flexbox">
                        <h2
                            className="yellowtxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.wron_en_programs_title,
                            }}
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: post.acf.wron_en_programs_content,
                            }}
                        />
                    </div>

                    <div className="whylistrgt flexbox">
                        <img
                            className="whiteborderimg"
                            src={
                                post.acf.wron_en_programs_image.localFile
                                    .childImageSharp.fluid.src
                            }
                            alt="wheels5"
                        />
                    </div>
                </div>
            </section>

            <section className="page-section">
                <div className="container flexwrap bdaytxtadjust">
                    <div className="whylistlft flexbox todwn">
                        <div
                            id="myCarousel5"
                            className="carousel slide"
                            data-ride="carousel"
                        >
                            <WeRockWheelsSlider
                                eqGallery={post.acf.wron_equipment_gallery}
                            />
                        </div>
                    </div>

                    <div className="whywelist whylistrgt flexbox toup">
                        <h2
                            className="bluetxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.wron_equipment_title,
                            }}
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: post.acf.wron_equipment_content,
                            }}
                        />
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default WeRockOnWheels;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
            acf {
                wron_book_description
                wron_book_title
                wron_call_us_number
                wron_description
                wron_en_programs_content
                wron_en_programs_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
                wron_en_programs_title
                wron_equipment_content
                wron_equipment_title
                wron_play_dates_content
                wron_play_dates_title
                wron_private_events_content
                wron_private_events_title
                wron_title
                wron_private_events_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
                wron_pricing_list {
                    wron_price_ammount
                    wron_price_description
                    wron_price_title
                }
                wron_play_dates_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
                wron_main_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
                wron_equipment_gallery {
                    localFile {
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
            }
        }
    }
`;
